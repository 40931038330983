import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { ModalHookReturnProps } from '../../../libs/componentsHooks/hooks.modal'
import { ArtWork } from '../../../api/parts/arts/types'
import TextArea from '../../../ui/TextArea/TextArea'
import ModalConfirm from '../../../ui/modals/ModalConfirm'
import { rejectArtWork } from '../model'
import styles from './DecisionControls.module.scss'

type RefuseModalProps = {
  modal: ModalHookReturnProps
  item: ArtWork
}

const RefuseModal = ({ modal, item }: RefuseModalProps) => {
  const [refuseReason, setRefuseReason] = useState('')

  const onReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRefuseReason(e.target.value)
  }

  const sendRefuseReason = () => {
    if (refuseReason.length < 3) {
      toast.warn('Длина текста должна быть больше трёх символов')
      return
    }
    rejectArtWork({ id: item.id, rejection_reason: refuseReason }).then(() => {
      setRefuseReason('')
      modal.hide()
    })
  }

  if (!modal.isVisible) return null

  return (
    <ModalConfirm
      title='Причина отказа'
      onClose={modal.hide}
      confirmLabel={'Отправить'}
      onConfirm={sendRefuseReason}
      style={{
        controls_container: styles.Modal_buttons_container,
      }}
    >
      <TextArea
        minLength={3}
        value={refuseReason}
        onChange={onReasonChange}
        style={{
          textarea: styles.TextArea,
          container: styles.TextArea_container,
        }}
      />
    </ModalConfirm>
  )
}

export default RefuseModal
