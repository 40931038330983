import { TextareaHTMLAttributes } from 'react'
import styles from './TextArea.module.scss'

export type TextAreaStyles = {
  container?: string
  textarea?: string
  label?: string
}

export type TextAreaProps = {
  name?: string
  label?: string
  style?: TextAreaStyles
} & Omit<TextareaHTMLAttributes<any>, 'style'>

const TextArea = ({ name = 'name', label, style, ...props }: TextAreaProps) => {
  return (
    <div className={[styles.container, style?.container].join(' ')}>
      <span className={[styles.label, style?.label].join(' ')}>{label}</span>
      <div className={styles.textarea_container}>
        <label>
          <textarea
            name={name}
            className={[styles.textarea, style?.textarea].join(' ')}
            {...props}
          />
        </label>
      </div>
    </div>
  )
}

export default TextArea
