import React from 'react'
import { useStore } from 'effector-react'
import classNames from 'classnames'
import { useModal } from '../../../libs/componentsHooks/hooks.modal'
import { ArtWork } from '../../../api/parts/arts/types'
import PaginationArrows from '../../../ui/Pagination/PaginationArrows'
import RefuseButton from '../../../ui/buttons/Button.Refuse'
import OkButton from '../../../ui/buttons/Button.Ok'
import Loader from '../../../ui/Loader/Loader'
import { approveArtWork } from '../model'
import RefuseModal from './RefuseModal'
import styles from './DecisionControls.module.scss'

type DecisionControlsProps = {
  item: ArtWork
  onNext: () => void
  onPrev: () => void
}

const DecisionControls: React.FC<DecisionControlsProps> = ({
  item,
  onNext,
  onPrev,
}) => {
  const modal = useModal()
  const isLoading = useStore(approveArtWork.pending)

  const onApprove = () => {
    approveArtWork(item.id)
  }

  return (
    <>
      {isLoading && <Loader fixed />}
      <RefuseModal modal={modal} item={item} />
      <div className={styles.control_buttons}>
        <PaginationArrows
          onPrev={onPrev}
          onNext={onNext}
          style={{ container: styles.Pagination_arrows_container }}
        >
          <RefuseButton
            label={'Отказать'}
            onClick={modal.show}
            style={{
              button: classNames(styles.decision_button),
            }}
          />
          <OkButton
            label={'Одобрить'}
            onClick={onApprove}
            style={{
              button: classNames(styles.decision_button),
            }}
          />
        </PaginationArrows>
      </div>
    </>
  )
}

export default DecisionControls
