import { objectToFormData } from '../../../libs/request/contentTypes'
import { apiManager } from '../../apiManager'
import {
  Category,
  AddCategoryImageProps,
  CreateCategoryProps,
  CreateCategoryResponse,
  AllCategoriesProps,
  AllCategoriesResponse,
  EditCategoryProps,
  AddCategoryImageResponse,
} from './types'

const categories = apiManager.endpoint('moderation/categories').protect()

const createCategory = categories.post<
  CreateCategoryResponse,
  CreateCategoryProps
>('create')
const edit = categories.patch<Category, EditCategoryProps>(
  ({ id, ...data }) => ({
    url: id,
    body: data,
  })
)
const getAll = categories.get<AllCategoriesResponse, AllCategoriesProps>('all')
const getSpesific = categories.get<Category, number>((id) => id)

const addImage = categories.put<
  AddCategoryImageResponse,
  AddCategoryImageProps
>(({ id, image }) => ({
  url: `${id}/upload-image`,
  contentType: null,
  body: objectToFormData({ image }),
}))

const deleteImage = categories.put<void, number>((id) => ({
  url: `${id}/delete-image`,
}))

export const categoriesApi = {
  getAll,
  getSpesific,
  addImage,
  createCategory,
  edit,
  deleteImage,
}
