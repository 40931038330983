// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArtsExamination_container__xwlGH {\n  display: flex;\n  justify-content: space-around; }\n\n.ArtsExamination_empty_list_note__eHWu\\+ {\n  width: 100%;\n  display: inline-block;\n  text-align: center; }\n", "",{"version":3,"sources":["webpack://./src/features/arts/ArtsExamination/ArtsExamination.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,6BAA6B,EAAA;;AAG/B;EACE,WAAW;EACX,qBAAqB;EACrB,kBAAkB,EAAA","sourcesContent":["@import '../../../utils/styles/mixins.scss';\n\n.container {\n  display: flex;\n  justify-content: space-around;\n}\n\n.empty_list_note {\n  width: 100%;\n  display: inline-block;\n  text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ArtsExamination_container__xwlGH",
	"empty_list_note": "ArtsExamination_empty_list_note__eHWu+"
};
export default ___CSS_LOADER_EXPORT___;
