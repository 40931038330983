import { useEffect, useState } from 'react'

export const usePagination = (defaultIndex: number, pagesCount: number) => {
  const [currentIndex, setCurrentIndex] = useState(defaultIndex)

  const moveNext = () => {
    const index = currentIndex + 1 >= pagesCount ? 0 : currentIndex + 1
    setCurrentIndex(index)
    return index
  }

  const movePrev = () => {
    const index = currentIndex - 1 < 0 ? pagesCount - 1 : currentIndex - 1
    setCurrentIndex(index)
    return index
  }

  const moveTo = (index: number) =>
    setCurrentIndex((prevIndex) =>
      index < 0 || index >= pagesCount ? prevIndex : index
    )

  return { currentIndex, moveNext, movePrev, moveTo }
}

export type UsePaginationListProps<T> = {
  items: T[]
  defaultPageIndex: number
  itemsPerPage: number
  totalItemsCount?: number
  isStaticList?: boolean
}

export function usePaginationList<T>({
  items,
  itemsPerPage,
  totalItemsCount,
  defaultPageIndex,
  isStaticList,
}: UsePaginationListProps<T>) {
  const [currentItems, setCurrentItems] = useState(items)
  const [pagesCount, setPagesCount] = useState(
    Math.ceil(
      (isStaticList ? items.length / itemsPerPage : totalItemsCount) || 1
    )
  )
  const [itemOffset, setItemOffset] = useState(0) //** Индекс первого элемента текущей страницы

  const {
    currentIndex: currentPageIndex,
    moveNext,
    movePrev,
    moveTo,
  } = usePagination(defaultPageIndex, pagesCount)

  useEffect(() => {
    const allItemsLength = totalItemsCount ?? items.length
    const newOffset = (currentPageIndex * itemsPerPage) % allItemsLength
    setItemOffset(newOffset)
  }, [currentPageIndex, totalItemsCount, items])

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage //** Индекс крайнего элемента текущей страницы
    const allItemsLength = totalItemsCount ?? items.length

    setCurrentItems(() =>
      isStaticList ? items.slice(itemOffset, endOffset) : items
    )
    setPagesCount(() => Math.ceil(allItemsLength / itemsPerPage))
  }, [itemOffset, itemsPerPage, items, totalItemsCount])

  const onPageChange = (pageIndex: number) => {
    moveTo(pageIndex)
    const pageNumber = pageIndex + 1
    const allItemsLength = totalItemsCount ?? items.length
    const newOffset = (pageIndex * itemsPerPage) % allItemsLength
    setItemOffset(newOffset)
    return { itemsPerPage, pageNumber }
  }

  return {
    pagesCount,
    onPageChange,
    currentItems,
    moveNext,
    movePrev,
    currentPageIndex,
  }
}
