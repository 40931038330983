import { apiManager } from '../../apiManager'
import { MyProfile } from './types'

const managers = apiManager.endpoint('users').protect()

const meEndpoint = managers.endpoint('me')

const me = meEndpoint.get<MyProfile>()

export const managersApi = {
  me,
}
