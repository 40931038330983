import { removeLastSlash } from '@heyheyjude/toolkit'

export function removeSlashStar(value: string) {
  if (value.endsWith('/*')) return value.slice(0, value.length - 2)
  return value
}

export function getRootPath(path: string) {
  return '/' + path.split('/')[1]
}

export function getLastPath(path: string) {
  return removeLastSlash(path).slice(path.lastIndexOf('/'))
}

export function isInnerPathActive(path: string, entirePath: string) {
  return getLastPath(entirePath) === path
}
