import {
  IdentityDocumentStatus,
  SubscriptionStatus,
} from '../api/parts/users/types.parts'

export const USERS_ITEMS_PER_PAGE = 8

export const USER_DOCUMENT_STATUS_DESCRIPTORS = {
  [IdentityDocumentStatus.DETERMINED]: 'верифицирован',
  [IdentityDocumentStatus.REJECTED]: 'не верифицирован',
  [IdentityDocumentStatus.PENDING]: 'на рассмотрении',
  [IdentityDocumentStatus.UNDETERMINED]: '-',
}

export const USER_SUBSCRIPTION_STATUS_DESCRIPTORS = {
  [SubscriptionStatus.PENDING]: 'на рассмотрении',
  [SubscriptionStatus.APPROVED]: 'подтверждена',
}
