import { Oval } from 'react-loader-spinner'
import styles from './Loader.module.scss'

type LoaderProps = {
  size?: number | string
  className?: string
  fixed?: boolean
}

const Loader = ({ size = 64, className = '', fixed }: LoaderProps) => {
  return (
    <div
      className={[styles.container, fixed && styles.fixed, className].join(' ')}
    >
      <Oval
        color={'#347b81'}
        height={size}
        width={size}
        strokeWidth={4}
        strokeWidthSecondary={2}
        secondaryColor={'#347b81'}
      />
    </div>
  )
}

export default Loader
