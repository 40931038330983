import { PropsWithChildren, useEffect } from 'react'
import { createPortal } from 'react-dom'
import styles from './Overlay.module.scss'

type OverlayProps = {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  style?: string
}

const Overlay = ({
  children,
  style,
  onClick,
}: PropsWithChildren<OverlayProps>) => {
  const overlayRoot = document.getElementById('overlay_root')

  useEffect(() => {
    const documentBody = document.querySelector('body')
    if (documentBody) documentBody.style.overflow = 'hidden'
    return () => {
      if (documentBody) documentBody.style.overflow = 'auto'
    }
  }, [])

  const onOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) onClick?.(e)
  }

  return (
    overlayRoot &&
    createPortal(
      <div
        className={[styles.overlay, style].join(' ')}
        onClick={onOverlayClick}
      >
        {children}
      </div>,
      overlayRoot
    )
  )
}

export default Overlay
