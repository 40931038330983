// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table_table_wrapper__0-Zkq {\n  overflow: auto;\n  border-radius: 8px;\n  border: 1px solid #b2bebd;\n  height: 100%; }\n\n.Table_table__UmQDY {\n  height: 100%;\n  width: 100%;\n  border-spacing: 0;\n  border-collapse: collapse;\n  border-style: hidden; }\n\n.Table_th__cWh20,\n.Table_td__kqWPJ {\n  border: 1px solid #b2bebd;\n  padding: 12px 16px;\n  text-align: left;\n  vertical-align: top;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  color: #303535; }\n  .Table_th_left_side_titles__2sfk8:first-child,\n  .Table_td_left_side_titles__PNPg-:first-child {\n    color: #728888; }\n", "",{"version":3,"sources":["webpack://./src/ui/tables/Table.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,YAAY,EAAA;;AAGd;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,oBAAoB,EAAA;;AAGtB;;EAEE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,cAAc,EAAA;EAEb;;IAEG,cAAc,EAAA","sourcesContent":[".table_wrapper {\n  overflow: auto;\n  border-radius: 8px;\n  border: 1px solid #b2bebd;\n  height: 100%;\n}\n\n.table {\n  height: 100%;\n  width: 100%;\n  border-spacing: 0;\n  border-collapse: collapse;\n  border-style: hidden;\n}\n\n.th,\n.td {\n  border: 1px solid #b2bebd;\n  padding: 12px 16px;\n  text-align: left;\n  vertical-align: top;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  color: #303535;\n\n  &_left_side_titles {\n    &:first-child {\n      color: #728888;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_wrapper": "Table_table_wrapper__0-Zkq",
	"table": "Table_table__UmQDY",
	"th": "Table_th__cWh20",
	"td": "Table_td__kqWPJ",
	"th_left_side_titles": "Table_th_left_side_titles__2sfk8",
	"td_left_side_titles": "Table_td_left_side_titles__PNPg-"
};
export default ___CSS_LOADER_EXPORT___;
