import { FaqLanguages } from '../api/parts/faq/types'

export const LANGUAGES_DESCRIPTORS = [
  {
    language: FaqLanguages.RU,
    label: 'Русский',
  },
  {
    language: FaqLanguages.EN,
    label: 'Английский',
  },
]

export const DEFAULT_LANGUAGE_DESCRIPTOR =
  LANGUAGES_DESCRIPTORS.find(({ language }) => language === FaqLanguages.RU) ||
  LANGUAGES_DESCRIPTORS[0]
