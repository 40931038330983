import PaginationNumbers from '../../../ui/Pagination/PaginationNumbers'
import Loader from '../../../ui/Loader/Loader'
import { PAGINATION_VISIBLE_NUMBERS } from '../../../constants'
import { useArtsList } from '../hooks'
import ArtWorkDataTable from '../ArtWorkData'
import DecisionControls from '../DecisionControls'
import ArtWorkImage from '../ArtWorkData/ArtWorkData.Image'
import styles from './ArtsExamination.module.scss'

const ArtsExamination = () => {
  const {
    currentArtWork,
    getNext,
    getPrev,
    getPage,
    isLoading,
    currentIndex,
    totalCount,
  } = useArtsList()

  if (totalCount === 0 && !isLoading)
    return (
      <span className={styles.empty_list_note}>
        Список работ, предоставленных на модерацию, пуст.
      </span>
    )

  if (!currentArtWork) return null

  return (
    <div className={styles.container}>
      {isLoading && <Loader fixed />}
      <ArtWorkDataTable item={currentArtWork} />
      <div>
        <ArtWorkImage item={currentArtWork} />
        <DecisionControls
          item={currentArtWork}
          onNext={getNext}
          onPrev={getPrev}
        />
        <PaginationNumbers
          arrayLength={totalCount}
          currentIndex={currentIndex}
          visibleNumbers={PAGINATION_VISIBLE_NUMBERS}
          onPageChange={getPage}
          style={{ container: styles.Pagination_container }}
        />
      </div>
    </div>
  )
}

export default ArtsExamination
