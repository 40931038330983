// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_overlay__yECTc {\n  background-color: rgba(0, 0, 0, 0.5); }\n\n.Modal_modal__PPo6A {\n  position: relative;\n  max-width: 90vw;\n  min-width: 400px;\n  min-height: 100px;\n  max-height: 95vh;\n  overflow: auto;\n  background-color: #fff;\n  padding: 40px 52px;\n  border-radius: 8px; }\n  .Modal_modal_close_icon__JrYdg {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n    cursor: pointer; }\n  .Modal_modal_title__jlCq2 {\n    font-weight: 500;\n    font-size: 18px;\n    color: #303535;\n    margin-bottom: 24px; }\n  .Modal_modal_content__JKUqs {\n    width: 100%;\n    margin-bottom: 20px; }\n", "",{"version":3,"sources":["webpack://./src/ui/modals/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAoC,EAAA;;AAGtC;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB,EAAA;EAElB;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe,EAAA;EAGjB;IACE,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,mBAAmB,EAAA;EAGrB;IACE,WAAW;IACX,mBAAmB,EAAA","sourcesContent":[".overlay {\n  background-color: rgba(0, 0, 0, 0.5);\n}\n\n.modal {\n  position: relative;\n  max-width: 90vw;\n  min-width: 400px;\n  min-height: 100px;\n  max-height: 95vh;\n  overflow: auto;\n  background-color: #fff;\n  padding: 40px 52px;\n  border-radius: 8px;\n\n  &_close_icon {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n    cursor: pointer;\n  }\n\n  &_title {\n    font-weight: 500;\n    font-size: 18px;\n    color: #303535;\n    margin-bottom: 24px;\n  }\n\n  &_content {\n    width: 100%;\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "Modal_overlay__yECTc",
	"modal": "Modal_modal__PPo6A",
	"modal_close_icon": "Modal_modal_close_icon__JrYdg",
	"modal_title": "Modal_modal_title__jlCq2",
	"modal_content": "Modal_modal_content__JKUqs"
};
export default ___CSS_LOADER_EXPORT___;
