import * as React from 'react'
import classNames from 'classnames'
import { ReactComponent as Arrow } from '../../../assets/icons/arrow.svg'
import styles from './Button.Arrow.module.scss'

type ButtonProps = Omit<React.ButtonHTMLAttributes<any>, 'type'>

type NavigateButtonProps = ButtonProps & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  type: 'next' | 'prev'
}

const ArrowButton = ({ onClick, type, ...props }: NavigateButtonProps) => (
  <button onClick={onClick} type='button' className={styles.button} {...props}>
    <Arrow
      className={classNames('button', styles.arrow, {
        [styles.next]: type === 'next',
      })}
    />
  </button>
)

export default ArrowButton
