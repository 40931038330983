import { lazy } from 'react'
import { paths } from '../paths'

const LoginScreen = lazy(
  () => import('../../screens/Screen.Login' /*webpackChunkName: "AuthScreen" */)
)

const PasswordRecoverScreen = lazy(
  () => import('../../screens/Screen.PasswordRecover' /*webpackChunkName: "PasswordRecoverScreen" */)
)


export const authRoutes = [
  {
    path: paths.auth,
    component: LoginScreen,
    isProtected: false,
  },
  {
    path: paths.passwordRecover,
    component: PasswordRecoverScreen,
    isProtected: false,
  }
]
