import {
  CategoryAccessType,
  CategoryStatus,
} from '../api/parts/categories/types'

export const CATEGORIES_ITEMS_PER_PAGE = 5

export const CATEGORY_NAME_MAX_LENGTH = 30

export type CategoryAccessTypeDescriptor = {
  id: CategoryAccessType['id']
  name: CategoryAccessType['name']
  label: string
}

export const CATEGORY_ACCESS_TYPES: CategoryAccessTypeDescriptor[] = [
  { id: 1, name: 'premium', label: 'премиальная' },
  { id: 2, name: 'public', label: 'общедоступная' },
]

export type CategoryStatusDescriptor = {
  id: CategoryStatus['id']
  name: CategoryStatus['name']
  label: string
}

export const CATEGORY_STATUSES: CategoryStatusDescriptor[] = [
  { id: 1, name: 'active', label: 'активная' },
  { id: 2, name: 'inactive', label: 'не активная' },
]
