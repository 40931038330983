import moment from 'moment'
import 'moment/locale/ru'

export function toSeconds(value: number) {
  return value / 1000
}

export function toMinutes(value: number) {
  return value / 60 / 1000
}

export function toHours(value: number) {
  return value / 60 / 60 / 1000
}

export function toDays(value: number) {
  return value / 24 / 60 / 60 / 1000
}

export function seconds(value: number) {
  return value * 1000
}

export function minutes(value: number) {
  return value * 60 * 1000
}

export function hours(value: number) {
  return value * 60 * 60 * 1000
}

export function days(value: number) {
  return value * 24 * 60 * 60 * 1000
}

export function years(value: number) {
  return value * 365 * 24 * 60 * 60 * 1000
}

export const getAgeFromBirthday = (birthdate: string) => {
  return moment().diff(birthdate, 'years')
}

export const dateObjectToString = (
  date: Date,
  format: string = 'YYYY-MM-DD'
) => {
  return moment(date.valueOf()).format(format)
}

export const formatDateString = (
  date: string,
  format: string = 'DD.MM.YYYY'
) => {
  return moment(date).format(format)
}
