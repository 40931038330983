import classNames from 'classnames'
import PaginationUtils from '../../libs/pagination'
import styles from './Pagination.module.scss'

export type PaginationNumbersStyle = {
  container?: string
}

type PaginationNumbersProps = {
  currentIndex: number
  visibleNumbers: number
  arrayLength: number
  onPageChange: (index: number) => void
  style?: PaginationNumbersStyle
}

const PaginationNumbers = ({
  currentIndex: index,
  visibleNumbers,
  arrayLength,
  onPageChange,
  style,
}: PaginationNumbersProps) => {
  const currentIndex = index > arrayLength - 1 ? arrayLength - 1 : index
  return (
    <div className={classNames(styles.pagination_numbers, style?.container)}>
      {PaginationUtils.getPaginationNumbers(
        currentIndex,
        visibleNumbers,
        arrayLength
      ).map((paginationNumber, index) => {
        const isDots = typeof paginationNumber === 'string'
        const isActive = currentIndex === paginationNumber
        return (
          <button
            key={index}
            className={classNames(styles.button, {
              [styles.active]: isActive,
              [styles.isDots]: isDots,
            })}
            onClick={() =>
              onPageChange(isDots ? currentIndex : paginationNumber)
            }
            disabled={isDots}
          >
            {isDots ? paginationNumber : paginationNumber + 1}
          </button>
        )
      })}
    </div>
  )
}

export default PaginationNumbers
