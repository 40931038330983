import { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import Overlay from '../Overlay/Overlay'
import styles from './Modal.module.scss'

export type ModalStyle = {
  modal?: string
  title?: string
  content_container?: string
}

type ModalProps = {
  title?: string
  onClose: () => void
  style?: ModalStyle
}

const Modal = ({
  title,
  onClose,
  children,
  style,
}: PropsWithChildren<ModalProps>) => {
  return (
    <Overlay onClick={onClose} style={styles.overlay}>
      <div
        className={classNames(styles.modal, style?.modal)}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon className={styles.modal_close_icon} onClick={onClose} />
        {title && (
          <h1 className={classNames(styles.modal_title, style?.title)}>
            {title}
          </h1>
        )}
        {children && (
          <div
            className={classNames(
              styles.modal_content,
              style?.content_container
            )}
          >
            {children}
          </div>
        )}
      </div>
    </Overlay>
  )
}

export default Modal
