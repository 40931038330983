import { ContentType } from '@heyheyjude/toolkit'
import { objectToFormEncoded } from '../../../libs/request/contentTypes'
import { apiManager } from '../../apiManager'
import { LoginBody, LoginResponse } from './types'

const auth = apiManager.endpoint('auth')
const login = auth.post<LoginResponse, LoginBody>({
  contentType: ContentType.FORM_ENCODED,
  endpoint: 'token',
  fn: (data) => ({
    body: objectToFormEncoded(data),
  }),
})

export const authApi = {
  login,
}
