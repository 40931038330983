import { ArtWork } from '../../../api/parts/arts/types'
import AutoSizedImage from '../../../ui/AutoSizedImage/AutoSizedImage'
import styles from './ArtWorkData.module.scss'

type ArtWorkImageProps = {
  item: ArtWork
}

const ArtWorkImage = ({ item }: ArtWorkImageProps) => {
  if (!item.image_thumbnail)
    return (
      <div className={styles.no_image_container}>
        <p>Фотография работы отсутствует</p>
      </div>
    )
  return (
    <AutoSizedImage
      maxWidth={570}
      maxHeight={window.innerHeight * 0.6}
      src={item.image_thumbnail}
    />
  )
}

export default ArtWorkImage
