import ScreenContainer from '../ui/ScreenContainer'
import ArtsExamination from '../features/arts/ArtsExamination'

const ArtsExaminationScreen = () => {
  return (
    <ScreenContainer>
      <ArtsExamination />
    </ScreenContainer>
  )
}

export default ArtsExaminationScreen
