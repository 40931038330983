import { PaginatedListResponse } from '../../types'

export enum FaqLanguages {
  RU = 'RU',
  EN = 'EN',
}

export type CreateFaqProps = {
  question_ru: string | null
  answer_ru: string | null
  question_en: string | null
  answer_en: string | null
}

export type FaqResponse = CreateFaqProps & {
  id: number
}

export type FaqProps = FaqResponse

export type FaqListResponse = PaginatedListResponse<FaqResponse>
