import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { removeLastSlash } from '@heyheyjude/toolkit'
import { paths } from './paths'
import { getRootPath, removeSlashStar } from './helpers'

type ChildrenPaths = { path: string }[]

export function useNavigateFirstRouteChild(
  parentPath: paths,
  childrenPaths: ChildrenPaths
) {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === parentPath) {
      navigate(removeSlashStar(parentPath) + childrenPaths[0].path)
    }
  }, [])
}

export function useRootPath() {
  const currentPath = useLocation().pathname
  return getRootPath(currentPath)
}

export function useParentPaths() {
  const currentPath = useLocation().pathname
  const path = removeLastSlash(currentPath)
  return path.slice(0, path.lastIndexOf('/'))
}
