import { NavLink, useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'
import classNames from 'classnames'
import { $isAuth } from '../../../features/auth/model'
import { logOut } from '../../../features/auth/model.logout'
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg'
import Button from '../../../ui/buttons/Button'
import { paths } from '../../paths'
import { HEADER_NAVIGATION_DESCRIPTORS } from '../../pathsDescriptors'
import { useRootPath } from '../../hooks'
import { removeSlashStar } from '../../helpers'
import styles from './Header.module.scss'

const Header = () => {
  const navigate = useNavigate()
  const isAuth = useStore($isAuth)
  const currentPath = useRootPath()

  const onLogout = () => {
    navigate(paths.auth)
    logOut()
  }

  return (
    <header className={styles.header}>
      <div className={styles.header_container}>
        <NavLink to={paths.main}>
          <div className={styles.header_logo}>
            <LogoIcon />
          </div>
        </NavLink>
        {isAuth && (
          <div className={styles.header_list}>
            {HEADER_NAVIGATION_DESCRIPTORS.map(({ path, title }) => (
              <NavLink
                key={path}
                className={classNames(styles.header_item, {
                  [styles.active]: currentPath === removeSlashStar(path),
                })}
                to={path}
              >
                {title}
              </NavLink>
            ))}
          </div>
        )}
        {isAuth && (
          <Button variant='primary' onClick={onLogout}>
            Выйти
          </Button>
        )}
      </div>
    </header>
  )
}

export default Header
