import { apiManager } from '../../apiManager'
import { UserListResponse, UsersListProps, User } from './types'
import { UpdateBirthdateProps, UpdateSubscriptionProps } from './types.api'

const users = apiManager.endpoint('moderation/users').protect()

const getAll = users.get<UserListResponse, UsersListProps>('all')
const downloadChildDocument = users.get<Response, number>((id) => ({
  url: `${id}/download-child-identity-document`,
  rawResponse: true,
}))
const approve = users.patch<void, number>(
  (id) => `${id}/determine-child-identity`
)
const reject = users.patch<void, number>((id) => `${id}/reject-child-identity`)
const updateBirthdate = users.patch<User, UpdateBirthdateProps>(
  ({ id, DOB }) => ({
    url: `${id}/update-date-of-birth`,
    body: { DOB },
  })
)
const updateSubscriptionDate = users.patch<User, UpdateSubscriptionProps>(
  ({ id, subscription_date_end }) => ({
    url: `${id}/update-subscription-date-end`,
    body: { subscription_date_end },
  })
)

const approveSubscription = users.patch<void, number>(
  (id) => `${id}/approve-subscription`
)
const rejectSubscription = users.patch<void, number>(
  (id) => `${id}/reject-subscription`
)

export const usersApi = {
  getAll,
  downloadChildDocument,
  approve,
  reject,
  updateBirthdate,
  updateSubscriptionDate,
  approveSubscription,
  rejectSubscription,
}
