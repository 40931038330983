// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DecisionControls_decision_button_selected__7yka8 {\n  background-color: #c9e8e4;\n  pointer-events: none; }\n\n.DecisionControls_decision_button__ogPfZ:not(:last-of-type) {\n  margin-right: 24px; }\n\n.DecisionControls_TextArea_container__hSEYG {\n  margin-bottom: 16px; }\n\n.DecisionControls_TextArea__E2JAv {\n  margin-bottom: 16px;\n  resize: none;\n  height: 120px; }\n\n.DecisionControls_Pagination_container__EaRMp {\n  margin-top: 30px; }\n\n.DecisionControls_Pagination_arrows_container__xhgyc {\n  margin-top: 24px;\n  margin-bottom: 36px;\n  justify-content: space-between; }\n\n.DecisionControls_Modal_buttons_container__FJ6sS {\n  width: -moz-fit-content;\n  width: fit-content; }\n", "",{"version":3,"sources":["webpack://./src/features/arts/DecisionControls/DecisionControls.module.scss"],"names":[],"mappings":"AAGE;EACE,yBAAyB;EACzB,oBAAoB,EAAA;;AAHxB;EAOI,kBAAkB,EAAA;;AAItB;EACE,mBAAmB,EAAA;;AAGrB;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa,EAAA;;AAIb;EACE,gBAAgB,EAAA;;AAGlB;EACE,gBAAgB;EAChB,mBAAmB;EACnB,8BAA8B,EAAA;;AAKhC;EACE,uBAAkB;EAAlB,kBAAkB,EAAA","sourcesContent":["@import '../../../utils/styles/mixins.scss';\n\n.decision_button {\n  &_selected {\n    background-color: #c9e8e4;\n    pointer-events: none;\n  }\n\n  &:not(:last-of-type) {\n    margin-right: 24px;\n  }\n}\n\n.TextArea_container {\n  margin-bottom: 16px;\n}\n\n.TextArea {\n  margin-bottom: 16px;\n  resize: none;\n  height: 120px;\n}\n\n.Pagination {\n  &_container {\n    margin-top: 30px;\n  }\n\n  &_arrows_container {\n    margin-top: 24px;\n    margin-bottom: 36px;\n    justify-content: space-between;\n  }\n}\n\n.Modal {\n  &_buttons_container {\n    width: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"decision_button_selected": "DecisionControls_decision_button_selected__7yka8",
	"decision_button": "DecisionControls_decision_button__ogPfZ",
	"TextArea_container": "DecisionControls_TextArea_container__hSEYG",
	"TextArea": "DecisionControls_TextArea__E2JAv",
	"Pagination_container": "DecisionControls_Pagination_container__EaRMp",
	"Pagination_arrows_container": "DecisionControls_Pagination_arrows_container__xhgyc",
	"Modal_buttons_container": "DecisionControls_Modal_buttons_container__FJ6sS"
};
export default ___CSS_LOADER_EXPORT___;
