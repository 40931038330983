import { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation, Location } from 'react-router-dom'
import { noop } from '@heyheyjude/toolkit'
import { apiManager } from '../../api/apiManager'
import { meRequest } from '../../features/profile/request'

export function useAppInit() {
  const navigate = useNavigate()
  const location = useLocation()
  const locationInfo = useRef<Location>()
  const [isInitialized, setIsInitialized] = useState(false)

  const requestProfile = async () => {
    return meRequest().then((myProfile) => {
      if (myProfile) {
        locationInfo?.current && navigate(locationInfo.current.pathname)
      }
    })
  }

  useEffect(() => {
    apiManager.token
      .onInit()
      .promise.then((token) => {
        if (token) return requestProfile()
        return Promise.reject()
      })
      .catch(noop)
      .finally(() => {
        setIsInitialized(true)
      })

    return () => {
      locationInfo.current = location
    }
  }, [])

  return isInitialized
}
