import { ArtWork } from '../../../api/parts/arts/types'
import {
  formatDateString,
  getAgeFromBirthday,
} from '../../../libs/helpers/date'

export type ArtInfoDescriptor = {
  label: string
  valueExtractor: (item: ArtWork) => string
}

export const ART_INFO_DESCRIPTORS: ArtInfoDescriptor[] = [
  {
    label: 'Автор',
    valueExtractor: ({ author }) => `${author.first_name} ${author.last_name}`,
  },
  {
    label: 'Телефон',
    valueExtractor: ({ author }) => author.phone_number,
  },
  { label: 'E-mail', valueExtractor: ({ author }) => author.email },
  {
    label: 'Возраст',
    valueExtractor: ({ author }) => `${getAgeFromBirthday(author.DOB)} лет`,
  },
  {
    label: 'Дата загрузки',
    valueExtractor: ({ created_date_time }) =>
      formatDateString(created_date_time),
  },
  {
    label: 'Конкурс',
    valueExtractor: ({ competition }) => competition.category.name_ru,
  },
  {
    label: 'Название работы',
    valueExtractor: ({ title }) => title,
  },
]
