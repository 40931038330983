export const FAQ_ITEMS_PER_PAGE = 8
export const FAQ_QUESTION_MAX_LENGTH = 60
export const FAQ_QUESTION_LIMIT_ERROR =
  'Длина вопроса не должна превышать 60 символов.'
export const FAQ_QUESTION_LIMIT_WARNING =
  'Достигнута максимальная длина вопроса (60 символов).'
export const FAQ_EMPTY_QUESTION_ERROR =
  'Запись без вопроса будет удалена. Вы действительно хотите завершить редактирование?'
export const FAQ_EMPTY_ANSWER_WARNING =
  'Вы хотите оставить некоторые вопросы без ответов?'
export const FAQ_ONLEAVE_PAGE_ERROR =
  'Есть не сохраненные вопросы! Вы действительно хотите покинуть страницу?'
