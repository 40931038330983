import React from 'react'
import styles from './ArtWorkData.module.scss'

type ArtInfoRawProps = {
  label: string
  value: string | React.ReactNode
}

const ArtInfoRaw = ({ label, value }: ArtInfoRawProps) => {
  return (
    <>
      <td className={styles.table_cell}>{label}: </td>
      <td className={styles.table_cell}>{value}</td>
    </>
  )
}

export default ArtInfoRaw
