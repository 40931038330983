import { apiManager } from '../../apiManager'
import { LoginResponse } from '../auth/types'

type ResetPasswordProps = {
  token: string
  password: string
}

const endpoint = apiManager.endpoint('reset-password')

const reset = endpoint.patch<LoginResponse, ResetPasswordProps>(
  ({ token, password }) => ({ url: `?token=${token}`, body: { password } })
)

export const resetAppPasswordApi = {
  reset
}
