import { Navigate, Outlet } from 'react-router-dom'
import { useStore } from 'effector-react'
import { $isAuth } from '../../features/auth/model'
import { paths } from '../paths'

type RoutePrivateProps = {
  redirectTo?: paths
}

const RoutePrivate = ({ redirectTo }: RoutePrivateProps) => {
  const isAuth = useStore($isAuth)

  return isAuth ? <Outlet /> : <Navigate to={redirectTo ?? paths.auth} />
}

export default RoutePrivate
