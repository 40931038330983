import { createEffect } from 'effector'
import { noop } from '@heyheyjude/toolkit'
import { api } from '../../api'
import { setMyProfile } from './model'

export const meRequest = createEffect(() => {
  return api.managers.me().catch(noop)
})

meRequest.done.watch(({ result }) => {
  if (result) setMyProfile(result)
})
