export enum IdentityDocumentStatus {
  UNDETERMINED = 1,
  PENDING,
  DETERMINED,
  REJECTED,
}

export enum SubscriptionStatus {
  PENDING = 1,
  APPROVED,
}

export type UserSubscription = {
  is_active: boolean
  date_start: string
  date_end: string
  type: {
    duration: number
    price: number
    full_amount: number
    id: number
  }
  payment_datetime: string
  status_id: SubscriptionStatus
}
