import { Navigate, Outlet } from 'react-router-dom'
import { useStore } from 'effector-react'
import { $isAuth } from '../../features/auth/model'
import { paths } from '../paths'

interface RoutePublicProps {
  redirectTo?: paths
}

const RoutePublic = ({ redirectTo }: RoutePublicProps) => {
  const isAuth = useStore($isAuth)

  return isAuth ? <Navigate to={redirectTo ?? paths.main} /> : <Outlet />
}

export default RoutePublic
