type CalculateImageSizeProps = {
  height: number
  maxHeight: number
  width: number
  maxWidth: number
}

export function calculateImageWidth({
  height,
  maxHeight,
  width,
  maxWidth,
}: CalculateImageSizeProps) {
  let ratio = 1
  if (height > width) {
    ratio = height / maxHeight
  }
  if (width > height) {
    ratio = width / maxWidth
  }
  if (width === height) {
    if (width > maxWidth) ratio = width / maxWidth
    if (height > maxHeight) ratio = height / maxHeight
  }
  return width / ratio
}
