// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScreenContainer_container__McU\\+B {\n  padding: 40px; }\n", "",{"version":3,"sources":["webpack://./src/ui/ScreenContainer/ScreenContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA","sourcesContent":[".container {\n  padding: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ScreenContainer_container__McU+B"
};
export default ___CSS_LOADER_EXPORT___;
