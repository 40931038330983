import { lazy } from 'react'
import { paths } from '../paths'
import ArtsExaminationScreen from '../../screens/Screen.ArtsExamination'

const QuestionsScreen = lazy(
  () =>
    import(
      '../../screens/Screen.Questions' /*webpackChunkName: "QuestionsScreen" */
    )
)
const ManagersScreen = lazy(
  () =>
    import(
      '../../screens/Screen.Managers' /*webpackChunkName: "ManagersScreen" */
    )
)
const UsersScreen = lazy(
  () =>
    import('../../screens/Screen.Users' /*webpackChunkName: "UsersScreen" */)
)

const SettingsScreen = lazy(
  () =>
    import(
      '../../screens/Screen.Settings' /*webpackChunkName: "SettingsScreen" */
    )
)

export const mainRoutes = [
  {
    path: paths.main,
    component: ArtsExaminationScreen,
    isProtected: true,
  },
  {
    path: paths.questions,
    component: QuestionsScreen,
    isProtected: true,
  },
  {
    path: paths.managers,
    component: ManagersScreen,
    isProtected: true,
  },
  {
    path: paths.users,
    component: UsersScreen,
    isProtected: true,
  },
  {
    path: paths.settings,
    component: SettingsScreen,
    isProtected: true,
  },
]
