import { apiManager } from '../../apiManager'
import { PaginatedListProps } from '../../types'
import { FaqListResponse, CreateFaqProps, FaqResponse, FaqProps } from './types'

const faq = apiManager.endpoint('moderation/faq').protect()
const getAll = faq.get<FaqListResponse, PaginatedListProps | void>('all')
const getSpecific = faq.get<FaqResponse, number>((id) => id)
const create = faq.post<FaqResponse, CreateFaqProps>()
const patch = faq.patch<FaqResponse, FaqProps>(({ id, ...data }) => ({
  url: id,
  body: data,
}))
const deleteQuestion = faq.delete<void, number>((id) => id)

export const faqApi = {
  getAll,
  getSpecific,
  create,
  patch,
  deleteQuestion,
}
