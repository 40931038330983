import { PropsWithChildren } from 'react'
import Button from '../../buttons/Button'
import Modal from '../Modal'
import styles from './ModalConfirm.module.scss'
import { ButtonProps } from '../../buttons/Button/Button'
import { ModalStyle } from '../Modal/Modal'

type ModalConfirmProps = {
  title?: string
  onClose: () => void
  buttonConfirmProps?: ButtonProps
  buttonRejectProps?: ButtonProps
  onConfirm?: () => void
  onReject?: () => void
  confirmLabel?: string
  rejectLabel?: string
  style?: ModalStyle & {
    controls_container?: string
  }
}

const ModalConfirm = ({
  title,
  children,
  onClose,
  buttonConfirmProps,
  buttonRejectProps,
  onReject,
  onConfirm,
  confirmLabel,
  rejectLabel,
  style,
}: PropsWithChildren<ModalConfirmProps>) => {
  const isRejectButtonDisplayed = onReject || buttonRejectProps
  const isConfirmButtonDisplayed = onConfirm || buttonConfirmProps

  return (
    <Modal onClose={onClose} style={{ modal: styles.modal, ...style }}>
      {title && <h1 className={styles.title}>{title}</h1>}
      {children && <div className={styles.content}>{children}</div>}
      <div
        className={[styles.controls_container, style?.controls_container].join(
          ' '
        )}
      >
        {isRejectButtonDisplayed && (
          <Button
            {...buttonRejectProps}
            label={buttonRejectProps?.label ?? rejectLabel ?? 'Нет'}
            onClick={buttonRejectProps?.onClick || onReject}
          />
        )}
        {isConfirmButtonDisplayed && (
          <Button
            variant='secondary'
            {...buttonConfirmProps}
            label={buttonConfirmProps?.label ?? confirmLabel ?? 'Да'}
            onClick={buttonConfirmProps?.onClick || onConfirm}
            className={!isRejectButtonDisplayed ? styles.single_button : ''}
          />
        )}
      </div>
    </Modal>
  )
}

export default ModalConfirm
