import React from 'react'
import classNames from 'classnames'
import Button, { ButtonProps } from '../Button'
import styles from './IconedButton.module.scss'

export type IconedButtonStyle = {
  label?: string
  button?: string
  icon?: string
}

export type IconedButtonProps = {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  style?: IconedButtonStyle
} & ButtonProps

const IconedButton = ({ Icon, onClick, label, style }: IconedButtonProps) => {
  return (
    <Button
      className={classNames(styles.button, style?.button)}
      onClick={onClick}
    >
      <Icon className={classNames(styles.icon, style?.icon)} />
      {label && (
        <span className={classNames(styles.label, style?.label)}>{label}</span>
      )}
    </Button>
  )
}

export default IconedButton
