export function objectToFormEncoded(data: Record<string, string | number>) {
  let stringsArray = []
  for (const key in data) {
    stringsArray.push(`${key}=${data[key]}`)
  }
  return stringsArray.join('&')
}

export function objectToFormData(data: Record<string, string | number | File>) {
  const formData = new FormData()
  for (const key in data) {
    const value = data[key]
    const valueToAppend = typeof value === 'number' ? value.toString() : value
    formData.append(key, valueToAppend)
  }
  return formData
}
