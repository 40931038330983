import { useState } from 'react'
import styles from './AutoSizedImage.module.scss'
import { calculateImageWidth } from './helpers'

export type AutoSizedImageStyle = {
  container?: string
}

type AutoSizedImageProps = {
  maxWidth: number
  maxHeight: number
  src: string
  style?: AutoSizedImageStyle
}

const AutoSizedImage = ({
  maxWidth,
  maxHeight,
  src,
  style,
}: AutoSizedImageProps) => {
  const [width, setWidth] = useState(maxWidth)

  return (
    <div className={[styles.container, style?.container].join(' ')}>
      <img
        onLoad={(e) => {
          // @ts-ignore
          const { naturalHeight, naturalWidth } = e.target
          setWidth(
            calculateImageWidth({
              height: naturalHeight,
              width: naturalWidth,
              maxHeight,
              maxWidth,
            })
          )
        }}
        className={styles.image}
        src={src}
        width={width}
      />
    </div>
  )
}

export default AutoSizedImage
