import { toast } from 'react-toastify'
import { attach } from 'effector'
import { api } from '../../api'
import { createPaginationListModel } from '../../libs/models/pagination'

export const artsListModel = createPaginationListModel({
  request: api.arts.getAll,
  idExtractor: (item) => item.id,
  pageSize: 1,
  totalCountGetter: ({ total }) => total,
  currentPageGetter: ({ page }) => page,
})

export const getCurrentPageArt = attach({
  source: {
    page: artsListModel.$currentPage,
    total: artsListModel.$totalCount,
  },
  mapParams: (_: void, { page, total }) => ({
    page: page === total && page > 1 ? page - 1 : page,
  }),
  effect: artsListModel.get,
})

export const approveArtWork = api.arts.approve
export const rejectArtWork = api.arts.reject

approveArtWork.done.watch(() => {
  getCurrentPageArt()
})

approveArtWork.fail.watch(() => {
  toast.error('Ошибка запроса')
})

rejectArtWork.done.watch(() => {
  getCurrentPageArt()
})

rejectArtWork.fail.watch(() => {
  toast.error('Ошибка запроса')
})
