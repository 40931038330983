import { useCallback } from 'react'
import WithoutHeaderTable from '../../../ui/tables/Table.WithoutHeader'
import { ArtWork } from '../../../api/parts/arts/types'
import { ART_INFO_DESCRIPTORS, ArtInfoDescriptor } from './descriptors'
import ArtInfoRaw from './ArtWorkData.TableRaw'
import styles from './ArtWorkData.module.scss'

type ArtInfoProps = {
  item: ArtWork
}

const ArtWorkDataTable = ({ item }: ArtInfoProps) => {
  const renderRawChildren = useCallback(
    ({ label, valueExtractor }: ArtInfoDescriptor) => {
      return <ArtInfoRaw label={label} value={valueExtractor(item)} />
    },
    [item]
  )

  return (
    <WithoutHeaderTable
      data={ART_INFO_DESCRIPTORS}
      renderRawChildren={renderRawChildren}
      columnsWidths={['37%']}
      style={{
        container: styles.container,
      }}
    />
  )
}

export default ArtWorkDataTable
