import React, { PropsWithChildren } from 'react'
import styles from './Button.module.scss'

export type ButtonProps = {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  primary?: boolean
  className?: string
  disabled?: boolean
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
  variant?: 'primary' | 'secondary' | 'tertiary' | 'transparent'
  label?: string
}

const Button = ({
  onClick,
  children,
  className = '',
  disabled,
  type = 'button',
  variant,
  label,
}: PropsWithChildren<ButtonProps>) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={[styles.button, styles[`button__${variant}`], className].join(
      ' '
    )}
    type={type}
  >
    {label ?? children}
  </button>
)

export default Button
