import React from 'react'
import classNames from 'classnames'
import { arrayFromNumber } from '../../libs/helpers/array'
import { TableStyle } from './types'
import styles from './Table.module.scss'

type TableProps<T> = {
  data: T[]
  renderRawChildren: (item: T) => React.ReactNode
  columnsCount?: number
  columnsWidths?: (number | string)[]
  style?: TableStyle<T>
}

const WithoutHeaderTable = <T,>({
  data,
  renderRawChildren,
  columnsCount = 2,
  columnsWidths,
  style,
}: TableProps<T>) => {
  return (
    <div className={classNames(styles.table_wrapper, style?.container)}>
      <table
        className={classNames(
          styles.table,
          styles.table_left_side_titles,
          style?.table
        )}
      >
        <colgroup>
          {arrayFromNumber(columnsCount).map((ind) => (
            <col
              key={ind}
              width={columnsWidths ? columnsWidths[ind] : undefined}
            />
          ))}
        </colgroup>
        <tbody className={style?.tbody}>
          {data.map((item, index) => {
            return (
              <tr key={index} className={style?.traw}>
                {renderRawChildren(item)}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default WithoutHeaderTable
