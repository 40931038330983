import { apiManager } from '../../apiManager'
import { PaginatedListProps } from '../../types'
import { RejectArtWorkProps, ArtWork, ArtWorksList } from './types'

const artWorks = apiManager.endpoint('moderation/arts').protect()

const getAll = artWorks.get<ArtWorksList, PaginatedListProps | void>('all')
const getSpecific = artWorks.get<ArtWork, number>((id) => id)
const approve = artWorks.patch<ArtWork, number>((id) => ({
  url: `${id}/approve`,
}))
const reject = artWorks.patch<ArtWork, RejectArtWorkProps>(
  ({ id, rejection_reason }) => ({
    url: `${id}/reject`,
    body: { rejection_reason },
  })
)

export const artsApi = {
  getAll,
  getSpecific,
  approve,
  reject,
}
