import { useStore, useStoreMap } from 'effector-react'
import { useEffect } from 'react'
import { usePagination } from '../../libs/componentsHooks/hooks.pagination'
import { artsListModel } from './model'

export function useArtsList() {
  useEffect(() => {
    get()
  }, [])

  const artsList = useStore(artsListModel.$items)
  const isLoading = useStore(artsListModel.$isLoading)
  const totalCount = useStore(artsListModel.$totalCount)

  const currentArtWork = useStoreMap({
    store: artsListModel.$items,
    keys: [],
    fn: (arts) => arts[0],
  })

  const get = artsListModel.getSync

  const { currentIndex, moveTo, moveNext, movePrev } = usePagination(
    0,
    totalCount
  )

  const getPrev = () => {
    const page = movePrev() + 1
    artsListModel.get({ page })
  }

  const getNext = () => {
    const page = moveNext() + 1
    artsListModel.get({ page })
  }

  const getPage = (pageIndex: number) => {
    moveTo(pageIndex)
    artsListModel.get({ page: pageIndex + 1 })
  }

  return {
    artsList,
    currentArtWork,
    totalCount,
    get,
    getPage,
    getPrev,
    getNext,
    isLoading,
    currentIndex,
  }
}
