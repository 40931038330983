import { ServerManager } from '@heyheyjude/toolkit'

export const ROOT_DOMAIN =
  window.location.hostname === 'localhost'
    ? 'test.art-sqrd.com'
    : window.location.hostname // 'art-sqrd.com'

export const server = new ServerManager({
  initialRoot: ROOT_DOMAIN,
  apiGenerator: (root) => `https://${root}/api/v1`,
})
