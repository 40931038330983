import { PropsWithChildren } from 'react'
import ArrowButton from '../buttons/Button.Arrow/Button.Arrow'
import styles from './Pagination.module.scss'
import classNames from 'classnames'

export type PaginationArrowsStyle = {
  container?: string
  children_container?: string
}

type PaginationArrowsProps = {
  onPrev: () => void
  onNext: () => void
  style?: PaginationArrowsStyle
  disabled?: boolean
  isPrevDisabled?: boolean
  isNextDisabled?: boolean
}

const PaginationArrows = ({
  children,
  onPrev,
  onNext,
  style,
  disabled,
  isPrevDisabled,
  isNextDisabled,
}: PropsWithChildren<PaginationArrowsProps>) => {
  return (
    <div className={classNames(styles.arrows_container, style?.container)}>
      <ArrowButton
        type='prev'
        onClick={onPrev}
        disabled={isPrevDisabled || disabled}
      />
      <div
        className={classNames(
          styles.arrows_children_container,
          style?.children_container
        )}
      >
        {children}
      </div>
      <ArrowButton
        type='next'
        onClick={onNext}
        disabled={isNextDisabled || disabled}
      />
    </div>
  )
}

export default PaginationArrows
