import { artsApi } from './parts/arts'
import { authApi } from './parts/auth'
import { faqApi } from './parts/faq'
import { managersApi } from './parts/managers'
import { categoriesApi } from './parts/categories'
import { usersApi } from './parts/users'
import { resetAppPasswordApi } from './parts/resetPassword'

export const api = {
  auth: authApi,
  faq: faqApi,
  managers: managersApi,
  arts: artsApi,
  categories: categoriesApi,
  users: usersApi,
  resetAppPassword: resetAppPasswordApi
}
