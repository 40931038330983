import { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import RoutePrivate from './navigation/components/RoutePrivate'
import RoutePublic from './navigation/components/RoutePublic'
import Header from './navigation/components/Header'
import { routes } from './navigation/routes'
import { useAppInit } from './libs/appInit'
import NotificationsContainer from './ui/Notifications'
import Loader from './ui/Loader'
import './utils/styles/App.scss'

const Page404 = lazy(() => import('./navigation/components/Page404/Page404'))

function App() {
  const isInitialized = useAppInit()

  if (!isInitialized) return <Loader />

  return (
    <div className='app'>
      <Header />
      <Suspense fallback={<Loader />}>
        <Routes>
          {routes.map(({ path, component: Component, isProtected }) =>
            isProtected ? (
              <Route key={path} path={'/'} element={<RoutePrivate />}>
                <Route path={path} element={<Component />} />
              </Route>
            ) : (
              <Route key={path} path={'/'} element={<RoutePublic />}>
                <Route path={path} element={<Component />} />
              </Route>
            )
          )}
          <Route />
          <Route path='*' element={<Page404 />} />
        </Routes>
      </Suspense>
      <NotificationsContainer />
    </div>
  )
}

export default App
